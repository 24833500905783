var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDialog
        ? _c(
            "div",
            [
              _c("merchant-user-dialog", {
                attrs: { "merchant-user-item": _vm.currentMerchantUserItem },
                on: { "on-success": _vm.handleSuccess },
                model: {
                  value: _vm.showDialog,
                  callback: function($$v) {
                    _vm.showDialog = $$v
                  },
                  expression: "showDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("Card", { ref: "header" }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "Button",
              {
                staticStyle: { "margin-left": "auto" },
                attrs: { type: "primary", icon: "md-add" },
                on: { click: _vm.handleAddMerchantUser }
              },
              [_vm._v("新增用户 ")]
            )
          ],
          1
        )
      ]),
      _c("Table", {
        attrs: {
          "row-key": "id",
          "tooltip-theme": _vm.$store.state.app.theme,
          height: _vm.tableHeight,
          loading: _vm.loading,
          columns: _vm.tableColumns,
          data: _vm.tableData,
          stripe: "",
          border: ""
        }
      }),
      _c("Page", {
        ref: "footer",
        staticStyle: { float: "right" },
        attrs: {
          total: _vm.total,
          current: _vm.currentPage,
          "show-total": "",
          "show-sizer": ""
        },
        on: {
          "on-change": _vm.handleCurrentChange,
          "on-page-size-change": _vm.handlePageSize
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }