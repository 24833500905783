<template>
    <Modal v-model="showDialog">
        <template slot="header">
            <Icon type="ios-information-circle"></Icon>
            <span>{{title}}</span>
        </template>

        <Form :label-width="120">
            <FormItem>
                <Input v-if="!merchantUserItem" v-model="userPhone" search enter-button placeholder="请输入用户手机号码"
                       @keydown.enter.native.prevent="" @on-search="handleSearchUser"/>
            </FormItem>
            <template v-if="merchantUser.userId || merchantUser.userId === 0 || merchantUser.id">
                <FormItem label="手机号码">
                    <Input v-model="merchantUser.phone" placeholder="请输入手机号码" disabled></Input>
                </FormItem>
                <FormItem label="用户昵称">
                    <Input v-model="merchantUser.nickName" placeholder="请输入用户昵称" disabled></Input>
                </FormItem>
                <FormItem label="用户角色">
                    <!-- 角色列表 -->
                    <CheckboxGroup v-model="merchantUser.merchantRoleIdList">
                        <div v-for="item in roleList" :key="item.id">
                            <Checkbox :label="item.id">{{item.name}}</Checkbox>
                        </div>
                    </CheckboxGroup>
                </FormItem>
            </template>
        </Form>

        <template slot="footer">
            <Button type="primary" :loading="loading" @click="saveForm('handleAddMerchantUserItem')"
                    v-if="!merchantUserItem">添加用户
            </Button>
            <Button type="primary" @click="saveForm('handleUpdateMerchantUserItem')" :loading="loading" v-else>修改用户
            </Button>
        </template>
    </Modal>
</template>

<script>
    import dialogMixin from '_m/dialog.mixin'

    export default {
        name: 'MerchantDialog',
        mixins: [dialogMixin],
        data () {
            return {
                showDialog: true,
                loading: false,
                userPhone: '',
                merchantUser: {
                    merchantRoleIdList: [],
                    phone: '',
                    nickName: '',
                    userId: null
                },
                roleList: [],
                checkedResourceObj: {}
            }
        },
        created () {
            if (this.merchantUserItem) {
                this.merchantUser.userId = this.merchantUserItem.id
                this.merchantUser.phone = this.merchantUserItem.phone
                this.merchantUser.nickName = this.merchantUserItem.nickName
                this.merchantUser.merchantRoleIdList = this.merchantUserItem.roleList.map(item => item.id)
            }

            this.getMerchantRoles()
        },
        computed: {
            title () {
                return this.merchantUserItem ? '修改用户' : '添加用户'
            }
        },
        props: {
            merchantUserItem: {
                type: Object,
                required: false,
                default: () => null
            }
        },
        methods: {
            getMerchantRoles () {
                this.$http.post(`/server/v1/MerchantRole/page`, {
                    pageNo: 1,
                    pageSize: 200,
                    t: {}
                }).then((response) => {
                    let { rows, code, msg } = response
                    if (rows) {
                        this.roleList = rows
                    } else {
                        this.$Notice.error({
                            title: `接口报错:code=${code}`,
                            desc: msg
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleSearchUser () {
                if (!this.userPhone) {
                    this.$Notice.error({
                        title: '输入错误',
                        desc: '请输入用户手机号码'
                    })
                } else {
                    this.$http.post(`/server/v1/User/info/phone?phone=${this.userPhone}`).then(response => {
                        const { code, data, msg } = response
                        if (code === 1) {
                            this.merchantUser.userId = data.id
                            this.merchantUser.phone = data.phone
                            this.merchantUser.nickName = data.nickName
                        } else {
                            this.$Notice.error({
                                title: `code=${code}`,
                                desc: msg
                            })
                        }
                    })
                }
            },
            // 保存数据到后端
            saveForm (funcName) {
                if (this.merchantUser.userId || this.merchantUser.userId === 0) {
                    this[funcName](this.merchantUser).then((response) => {
                        let { code, msg } = response
                        if (code === 1) {
                            this.$emit('on-success')
                            this.showDialog = false
                        } else {
                            this.$Notice.error({
                                title: `添加失败`,
                                desc: msg
                            })
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    this.$Notice.error({
                        title: '添加错误',
                        desc: '请先根据手机号码搜索出需要添加的用户账号'
                    })
                }
            },
            //  添加表单项
            handleAddMerchantUserItem (data) {
                return this.$http.post('/server/v1/MerchantUser/add', data)
            },
            // 修改表单项
            handleUpdateMerchantUserItem (data) {
                return this.$http.post(`/server/v1/MerchantRoleUser/update`, data)
            }
        }
    }
</script>

<style scoped>
</style>
