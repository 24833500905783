/**
 * 表格分页混入文件
 */
export default {
  data () {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false
    }
  },
  methods: {
    handleSearch () {
      // 这里实际上是重置页面为第一页，然后再执行initData方法
      this.currentPage = 1
      this.initData()
  },
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.initData()
    },
    handlePageSize (pageSize) {
      this.pageSize = pageSize
      this.initData()
    },
    handleModalCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.handleGetMemberWallet()
    },
    handleModalPageSize (pageSize) {
      this.pageSize = pageSize
      this.handleGetMemberWallet()
    }
  }
}
