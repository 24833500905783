<template>
    <div>
        <!--用户表单模态框-->
        <div v-if="showDialog">
            <merchant-user-dialog v-model="showDialog" :merchant-user-item="currentMerchantUserItem"
                                  @on-success="handleSuccess"/>
        </div>

        <Card ref="header">
            <div style="display: flex">
                <Button type="primary" icon="md-add" style="margin-left: auto;" @click="handleAddMerchantUser">新增用户
                </Button>
            </div>
        </Card>
        <!--表格-->
        <Table row-key="id"
               :tooltip-theme="$store.state.app.theme"
               :height="tableHeight"
               :loading="loading"
               :columns="tableColumns"
               :data="tableData"
               stripe
               border></Table>

        <!-- 分页 -->
        <Page ref="footer" :total="total" :current="currentPage" show-total show-sizer @on-change="handleCurrentChange"
              @on-page-size-change="handlePageSize" style="float: right;"/>
    </div>
</template>

<script>

    import tableHeightMixin from '_m/tableHeight.mixin'
    import tablePageMixin from '_m/tablePage.mixin'
    import MerchantUserDialog from './MerchantUserDialog'

    export default {
        name: 'MerchantUser',
        mixins: [tableHeightMixin, tablePageMixin],
        components: {
            MerchantUserDialog
        },
        data () {
            return {
                tableData: [],
                showDialog: false,
                currentMerchantUserItem: null
            }
        },
        created () {
            this.initData()
        },
        computed: {
            tableColumns () {
                let columns = []
                columns.push({
                    title: '手机号码',
                    key: 'phone',
                    align: 'center',
                })
                columns.push({
                    title: '昵称',
                    tree: true,
                    key: 'nickName',
                    align: 'center',
                })
                columns.push({
                    title: '真实姓名',
                    key: 'userName',
                    align: 'center',
                })
                columns.push({
                    title: '角色',
                    align: 'center',
                    render: (h, params) => {
                        const { row } = params
                        if (row.roleList && row.roleList.length) {
                            const rolesString = row.roleList.reduce((accumulator, currentValue) => (accumulator ? `${accumulator},` : '') + currentValue.name, '')

                            return h('span', rolesString)
                        }
                    }
                })
                columns.push({
                    title: '操作',
                    align: 'center',
                    width: 200,
                    render: (h, params) => {
                        const array = []
                        const { row } = params
                        array.push(h('Button', {
                            style: {
                                marginRight: '5px'
                            },
                            props: {
                                icon: 'ios-cog',
                                type: 'warning',
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.handleUpdateMerchantUserItem(row)
                                }
                            }
                        }, '修改'))
                        array.push(h('Button', {
                            props: {
                                icon: 'ios-trash',
                                type: 'error',
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.handleDeleteMerchantUserItem(row)
                                }
                            }
                        }, '删除'))
                        return h('div', array)
                    }
                })
                return columns
            }
        },
        methods: {
            initData () {
                this.loading = true
                this.$http.post(`/server/v1/MerchantUser/page`, {
                    pageNo: this.currentPage,
                    pageSize: this.pageSize,
                    t: {
                        status: 0
                    }
                }).then((response) => {
                    let { rows, code, msg, totalRows } = response
                    if (rows) {
                        this.tableData = rows
                        this.total = totalRows
                    } else {
                        this.$Notice.error({
                            title: `接口报错:code=${code}`,
                            desc: msg
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleSuccess () {
                this.initData()
            },
            // 添加角色
            handleAddMerchantUser () {
                this.currentMerchantUserItem = null
                this.showDialog = true
            },
            // 修改角色
            handleUpdateMerchantUserItem (item) {
                this.currentMerchantUserItem = item
                this.showDialog = true
            },
            // 删除角色
            handleDeleteMerchantUserItem (merchantUser) {
                this.$Modal.confirm({
                    title: '删除确认',
                    content: `<p>确认删除用户-${merchantUser.nickName || merchantUser.phone}?</p>`,
                    onOk: () => {
                        this.$http.delete(`/server/v1/MerchantUser/delete/${merchantUser.id}`).then((response) => {
                            const { code, msg } = response
                            if (code === 1) {
                                this.initData()
                            } else {
                                this.$Notice.error({
                                    title: `接口报错:code=${code}`,
                                    desc: msg
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .menu-option {
        display: flex;
        margin-bottom: 10px;
    }

    .m-t {
        margin-top: 16px;
    }
</style>
