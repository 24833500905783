var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("Icon", { attrs: { type: "ios-information-circle" } }),
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ],
        1
      ),
      _c(
        "Form",
        { attrs: { "label-width": 120 } },
        [
          _c(
            "FormItem",
            [
              !_vm.merchantUserItem
                ? _c("Input", {
                    attrs: {
                      search: "",
                      "enter-button": "",
                      placeholder: "请输入用户手机号码"
                    },
                    on: { "on-search": _vm.handleSearchUser },
                    nativeOn: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                      }
                    },
                    model: {
                      value: _vm.userPhone,
                      callback: function($$v) {
                        _vm.userPhone = $$v
                      },
                      expression: "userPhone"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.merchantUser.userId ||
          _vm.merchantUser.userId === 0 ||
          _vm.merchantUser.id
            ? [
                _c(
                  "FormItem",
                  { attrs: { label: "手机号码" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入手机号码", disabled: "" },
                      model: {
                        value: _vm.merchantUser.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.merchantUser, "phone", $$v)
                        },
                        expression: "merchantUser.phone"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "FormItem",
                  { attrs: { label: "用户昵称" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入用户昵称", disabled: "" },
                      model: {
                        value: _vm.merchantUser.nickName,
                        callback: function($$v) {
                          _vm.$set(_vm.merchantUser, "nickName", $$v)
                        },
                        expression: "merchantUser.nickName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "FormItem",
                  { attrs: { label: "用户角色" } },
                  [
                    _c(
                      "CheckboxGroup",
                      {
                        model: {
                          value: _vm.merchantUser.merchantRoleIdList,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.merchantUser,
                              "merchantRoleIdList",
                              $$v
                            )
                          },
                          expression: "merchantUser.merchantRoleIdList"
                        }
                      },
                      _vm._l(_vm.roleList, function(item) {
                        return _c(
                          "div",
                          { key: item.id },
                          [
                            _c("Checkbox", { attrs: { label: item.id } }, [
                              _vm._v(_vm._s(item.name))
                            ])
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.merchantUserItem
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.saveForm("handleAddMerchantUserItem")
                    }
                  }
                },
                [_vm._v("添加用户 ")]
              )
            : _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.saveForm("handleUpdateMerchantUserItem")
                    }
                  }
                },
                [_vm._v("修改用户 ")]
              )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }