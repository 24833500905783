/**
 * 自动计算表格高度
 * 表格页面分为 header body footer 三部分，body 为表格的高度
 * 通过ref 获取三部分节点，获取节点的时候存在两种可能性 存在/不存在  vue组件/html元素
 */
import { debounce } from '@/utils'

export default {
    data () {
        return {
            tableHeight: 0
        }
    },
    mounted () {
        this.handleCalcTableHeight()
        const resize = debounce(() => {
            this.handleCalcTableHeight()
        }, 300)
        window.addEventListener('resize', resize)
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('resize', resize)
        })
        // this.handleCalcTableHeight()
        // window.addEventListener('resize', resize)
        // this.$once('hook:beforeDestroy', () => {
        //     window.removeEventListener('resize', resize)
        // })
    },
    // watch: {
    // },
    methods: {
        /**
         * 计算表格所需要的高度
         */
        handleCalcTableHeight () {
            this.$nextTick().then(() => {
                const margin = 10
                const header = this.$refs['header']
                let headerHeight = 0
                if (header) {
                    // 普通的 html节点
                    const el = header.nodeType !== undefined ? header : header.$el
                    headerHeight = el.getBoundingClientRect().height + margin
                    el.style.marginBottom = `${margin}px`
                }
                const footer = this.$refs['footer']
                let footerHeight = 0
                if (footer) {
                    // 普通的 html节点
                    const el = footer.nodeType !== undefined ? footer : footer.$el
                    footerHeight = el.getBoundingClientRect().height + margin
                    el.style.marginTop = `${margin}px`
                }
                this.tableHeight = this.$el.getBoundingClientRect().height - (headerHeight + footerHeight)
            })
        }
    }
}
